import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 50%;
  z-index: 10;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  color: #fff;
  font-size: 0.875rem;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  transform: translateX(-50%);
  animation: alertFadeInfadeOut ${props => props.timeout}ms forwards;

  @keyframes alertFadeInfadeOut {
    0% { opacity: 0; }
    12.5% { opacity: 1; }
    87.5% { opacity: 1; }
    100% { opacity: 0; }
  }
`

export default function Alert({ message, timeout = 4000, ...props }) {
  return (
    <Wrapper timeout={timeout} {...props}>
      {message}
    </Wrapper>
  )
}
