import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { Helmet } from "react-helmet"
import condenseWhitespace from "condense-whitespace"

export default function NwHelmet({ lang, path = null, title, desc, image, is404 = false, noIndex = false }) {
  const intl = useIntl()
  const siteTitle = intl.formatMessage({ id: "siteTitle" })
  const siteUrl = intl.formatMessage({ id: "siteUrl" })
  const missingProps = [lang ? null : "lang", path === null && !is404 && !noIndex ? "path" : null].filter((n) => n)

  if (missingProps.length) {
    throw new Error(`Missing required props: ${missingProps.join(", ")}`)
  }

  if (!siteUrl) {
    throw new Error("No site URL specified")
  }

  let transformedPath = path
  let url

  if (!is404 && !noIndex) {
    if (path !== "" && !path.startsWith("/")) {
      transformedPath = "/" + transformedPath
    }

    if (path.endsWith("/")) {
      transformedPath = transformedPath.replace(/\/$/, "")
    }

    url = `${siteUrl.endsWith("/") ? siteUrl.replace(/\/$/, "") : siteUrl}${transformedPath}`
  }

  const ogTags = [{
    property: "title",
    content: title || siteTitle,
  }, {
    property: "type",
    content: "website",
  }, {
    property: "url",
    content: url,
  }, {
    property: "site_name",
    content: siteTitle,
  }]

  if (image) {
    ogTags.push({
      property: "image",
      content: image.url,
    })
    ogTags.push({
      property: "image:type",
      content: image.type || "image/jpeg",
    })
    if (image.width) {
      ogTags.push({
        property: "image:width",
        content: image.width,
      })
    }
    if (image.height) {
      ogTags.push({
        property: "image:height",
        content: image.height,
      })
    }
    if (image.alt) {
      ogTags.push({
        property: "image:alt",
        content: image.alt,
      })
    }
  }

  if (desc) {
    ogTags.push({
      property: "description",
      content: condenseWhitespace(desc),
    })
  }

  const ogTagsWithId = ogTags.map((tag, index) => ({ ...tag, id: index }))

  return (
    <Helmet title={title ? `${title} | ${siteTitle}` : siteTitle}>
      <html lang={lang} />
      {(!is404 && !noIndex) &&
        <link rel="canonical" href={url} />
      }
      {(is404 || noIndex) &&
        <meta name="robots" content="noindex" />
      }
      {!is404 && ogTagsWithId.map(({ id, property, content }) => (
        <meta key={id} property={`og:${property}`} content={content} />
      ))}
      {!is404 && desc && <meta key="1" name="description" content={condenseWhitespace(desc)} />}
    </Helmet>
  )
}
