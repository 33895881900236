import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const buttonStyle = `
  position: relative;
  width: 2.625rem;
  height: 2.625rem;
  margin: 0;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  appearance: none;
  text-decoration: none;

  > *:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 1.2rem;
    max-height: 1.2rem;
    transform: translateX(-50%) translateY(-50%);
  }
`

const Button = styled.button`
  ${buttonStyle}
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
`

const StyledLink = styled(Link)`
  ${buttonStyle}
  display: inline-block;
`

const Bg = ({cid}) => (
  <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle cx="40" cy="40" r="40" fill="#E20000"/>
      <linearGradient id={`btn-a-${cid}`} x1="40" x2="40" y1="1.1889e-5" y2="80" gradientUnits="userSpaceOnUse">
        <stop stopColor="#f00" offset="0"/>
        <stop stopColor="#EB0000" offset=".2898"/>
        <stop stopColor="#E20000" offset=".5"/>
        <stop stopColor="#D90000" offset=".66"/>
        <stop stopColor="#C10000" offset=".9202"/>
        <stop stopColor="#B80000" offset="1"/>
      </linearGradient>
      <path d="m40 5c19.3 0 35 15.7 35 35s-15.7 35-35 35-35-15.7-35-35 15.7-35 35-35m0-5c-22.09 0-40 17.91-40 40s17.91 40 40 40 40-17.91 40-40-17.91-40-40-40z" fill={`url(#btn-a-${cid})`}/>
    </g>
  </svg>
)

const ActionButton = ({ children, ...props }) => {
  const cid = props.className ? props.className.replace(/\s/g, "") : null
  const Wrapper = props.to ? StyledLink : Button
  return (
    <Wrapper {...props}>
      <Bg cid={cid}/>
      {children}
    </Wrapper>
  )
}

export default ActionButton
