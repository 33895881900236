const slugify = require("slugify")
const condenseWhitespace = require("condense-whitespace")

const getSlugFromString = (string = "", options) =>
  slugify(
    condenseWhitespace(
      string.replace(/&/g, "").replace(/s's/g, "s")
    ),
    options || { lower: true, strict: true }
  )

module.exports = getSlugFromString
