import { renderToStaticMarkup } from "react-dom/server"
import btoa from "btoa"

export default function base64svg(reactElement, type = null) {
  const rend = renderToStaticMarkup(reactElement)

  switch(type) {
    case "utf8":
      return "data:image/svg+xml;utf8," + encodeURIComponent(rend)
      break
    case "none":
      return "data:image/svg+xml;" + rend
      break
    case "base64":
    default :
      return "data:image/svg+xml;base64," + btoa(rend)
      break
  }
}
