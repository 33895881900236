import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import Cookies from "js-cookie"

import { useStore } from "../hooks/store"
import getUserAgent from "../utils/get-user-agent"

import warningIcon from "../images/warning-icon.svg"

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width:100%;
  height: 100%;
  text-align: center;
  color: black;
  background-color: rgba(0, 0, 0, .55);
`

const Wrapper = styled.div`
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  z-index: 1100;
  width: 90%;
  max-width: 58rem;
  margin: 0 auto;
  padding: 5.875rem 2.5rem 3.875rem;
  border: 0.1875rem solid white;
  border-radius: 2.5rem;
  text-align: left;
  background: #B80000;
  box-shadow: 0 1.125rem 1.125rem rgba(102, 102, 102, 0.25);

  @media only screen and (min-width: 500px) {
    padding: 2.125rem 2.5rem 1.875rem;
  }
`

const Icon = styled.span`
  position: absolute;
  top: 1.6875rem;
  left: 2rem;
  display: inline-block;
  width: 2.625rem;
  height: 2.625rem;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${warningIcon}) no-repeat center;
    background-size: contain;
  }
`

const MainMessage = styled.b`
  display: block;
  margin-bottom: 1.625rem;
  font: normal 1.125rem/1.9375rem ${(props) => props.theme.headingFont};
  color: white;

  @media only screen and (min-width: 500px) {
    padding-left: 2.5rem;
  }
`

const MoreMessage = styled.span`
  display: block;
  margin-bottom: 2.8125rem;
  font: 1.125rem/1.5625rem ${(props) => props.theme.secondaryFont};
  color: white;

  @media only screen and (min-width: 500px) {
    margin-bottom: 1.5rem;
  }
`

const Buttons = styled.div`
  a,
  button {
    display: block;
    margin: 0 0.125rem;
    padding: 0.3125rem 0.9375rem;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    font: 0.875rem/1.375rem ${(props) => props.theme.headingFont};
  }

  a {
    margin-bottom: 1.625rem;
    background: white;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 9999px;
    color: #B80000;
  }

  button {
    width: 100%;
    margin: 0;
    border: 0;
    background: none;
    color: #EDBFBF;
    color: rgba(255, 255, 255, 0.75);
  }

  @media only screen and (min-width: 500px) {
    a,
    button {
      display: inline-block;
      float: right;
    }

    a {
      margin-left: 1.125rem;
      margin-bottom: 0;
    }

    button {
      width: auto;
    }
  }
`

export default function BrowserUpdateMessage() {
  const intl = useIntl()
  const [store, updateStore] = useStore()
  const [showMessage, setShowMessage] = useState(false)
  const ua = getUserAgent()

  useEffect(() => {
    if (!store.hideBrowserUpdateMessage && ua.browser.name === "IE") {
      setShowMessage(true)
    }

    // Remove Browser-Update.org cookies for users that already have them
    // TODO: Remove this eventually
    Cookies.remove("browserupdateorg")
    Cookies.remove("__cfduid", { path: "/", domain: ".browser-update.org" })
    Cookies.remove("__gads", { path: "/", domain: ".browser-update.org" })
    Cookies.remove("_ga", { path: "/", domain: ".browser-update.org" })
    Cookies.remove("_gid", { path: "/", domain: ".browser-update.org" })
    Cookies.remove("session", { path: "/", domain: "browser-update.org" })
  }, [])

  useEffect(() => {
    if (!showMessage) {
      updateStore((store) => {
        store.hideBrowserUpdateMessage = true
      })
    }
  }, [showMessage])

  return !showMessage ? null : (
    <>
      <Overlay onClick={() => setShowMessage(false)} />
      <Wrapper role="status" aria-live="polite">
        <Icon />
        <MainMessage>
          {intl.formatMessage(
            { id: "broswerUpdateMainMsg" },
            { browser: `Internet Explorer ${ua.browser.major}` }
          )}
        </MainMessage>
        <MoreMessage>{intl.formatMessage({ id: "broswerUpdateMoreMsg" })}</MoreMessage>
        <Buttons>
          <a
            href="https://browser-update.org/update-browser.html"
            target="_blank"
            rel="noreferrer noopener"
          >
            {intl.formatMessage({ id: "updateBrowser" })}
          </a>
          <button type="button" onClick={() => setShowMessage(false)}>
            {intl.formatMessage({ id: "ignoreAndClose" })}
          </button>
        </Buttons>
      </Wrapper>
    </>
  )
}
