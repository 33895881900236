import React from "react"

export default function CaretUp({ ...props }) {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        transform="rotate(180,4,4)"
        d="M3 7.4L.3 1.8C-.1 1.4-.1 1 .2.6.4.2.7 0 1 0h5.8c.4 0 .7.2 1 .6l.1.6c0 .2 0 .4-.2.6L5 7.4c-.2.4-.5.6-.9.6s-.7-.3-1-.6z"
      />
    </svg>
  )
}
