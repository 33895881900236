import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import get from "lodash/get"

import { useModal } from "../hooks/modal"

export default function CookieLink({ lang, text, linkToSettings = false, onClick }) {
  const { closeModal } = useModal()
  const { allPagesYaml } = useStaticQuery(graphql`
    query {
      allPagesYaml(filter: { _id: { eq: "/cookies-och-dataskydd" } }) {
        nodes {
          lang
          path
          title
        }
      }
    }
  `)

  const page = allPagesYaml.nodes.find((x) => x.lang === lang)

  return (
    <Link to={`/${lang}${get(page, "path")}${linkToSettings ? "#cookies" : ""}`} onClick={onClick || closeModal}>
      {text || get(page, "title")}
    </Link>
  )
}
