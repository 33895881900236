import parser from "ua-parser-js"

let ua

export default function getUserAgent() {
  if (typeof window === "undefined") {
    return {}
  }

  if (ua) {
    return ua
  }

  const result = parser(window.navigator.userAgent)

  ua = {
    ...result,
    isMobile: result.device.type === "mobile" || result.device.type === "tablet",
  }

  return ua
}
