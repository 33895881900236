import React, { useState, useEffect, createRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import PlayIcon from "./icons/Play"
import PauseIcon from "./icons/Pause"

import secondsToTime from "../utils/seconds-to-time"
import trackEvent from "../utils/track-event"

const Wrapper = styled.div`
  overflow: visible;
  border-radius: 0.3175rem;
`

const Inner = styled.div`
  display: block;
  max-width: 100%;
  border-radius: 0.3125rem;
  overflow: hidden;
  border-radius: inherit;

  :focus-within {
    box-shadow:
      inset 0 0 1px 1px rgba(255,255,255,0.4),
      0 0 .4rem rgba(0,0,0,0.6);
  }
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 2.5rem;
  padding: 0.625rem;
  color: white;
  font-weight: bold;
  font-size: 0.875rem;
  text-align: center;
  background: rgba(255,255,255,0.1);
  border: 1px solid rgba(255,255,255,0.3);
  border-radius: inherit;
`

const Button = styled.button`
  margin-left: 0;
  margin-right: auto;
  padding: 0.4375rem;
  border: 0;
  background: transparent;
  transition: background 0.3s ease;

  :hover {
    background: rgba(0, 0, 0, 0.25);
  }

  svg {
    display: block;
  }

  ${props => props.themeColor && `
    svg * { fill: rgb(${props.themeColor}) !important; }
  `}
`

const Progress = styled.div`
  flex: 1 1;
  min-width: 0;
  margin-left: 2.5px;
  padding-left: 2.5px;
`

const sliderStyle = {
  track : `
    cursor: pointer;
    border-radius: 999px;
    height: 0.6rem;
    background: linear-gradient(rgba(255,255,255,0.75), rgba(255,255,255,0.75)) no-repeat rgba(0,0,0,0.15);
  `,
  thumb : `
    -webkit-appearance: none;
    appearance: none;
    border: none;
    height: 1rem;
    width: 1rem;
    margin-top: -.2rem;
    border-radius: 100%;
    box-shadow: 0px 1px .2rem rgba(0,0,0,0.3);
  `,
  thumbFocus : `
    box-shadow: 0px 1px .6rem rgba(0,0,0,0.7);
  `
}

const Slider = styled.input`
  display: block;
  position: relative;
  width: 100%;
  background: transparent;
  -webkit-appearance: none;
  cursor: pointer;

  :focus {
    outline: 0;
  }

  ::-webkit-slider-runnable-track {
    ${sliderStyle.track}
    background-size: ${props => props.value}% 100%;
  }

  ::-moz-range-track {
    ${sliderStyle.track}
    background-size: ${props => props.value}% 100%;
  }

  :focus::-webkit-slider-runnable-track {
    background-image: linear-gradient(rgba(255,255,255,0.8) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,0.8));
  }
  :focus::-moz-range-track {
    background-image: linear-gradient(rgba(255,255,255,0.8) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,0.8));
  }

  ::-webkit-slider-thumb {
    ${sliderStyle.thumb}
    background: rgba(255,255,255,1);
  }
  ::-moz-range-thumb {
    ${sliderStyle.thumb}
    background: rgba(255,255,255,1);
  }

  :focus::-webkit-slider-thumb {${sliderStyle.thumbFocus}}
  :focus::-moz-range-thumb {${sliderStyle.thumbFocus}}

  ::-ms-track {
    {${sliderStyle.track}}
    background-size: ${props => props.value}% 100%;
  }

  ::-ms-thumb {
    background: rgba(255,255,255,1);
  }

  ::-ms-fill-lower {
    rgba(255,255,255,0.7);
  }

  ::-ms-fill-upper {
    rgba(0,0,0,0.2);
  }

  @supports (-ms-ime-align:auto) {
    /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }

  ${props => props.themeColor && !props.themeColor.match("#") && `
    ::-webkit-slider-runnable-track {background-image: linear-gradient(rgba( ${props.themeColor} ,0.7), rgba( ${props.themeColor} ,0.7))}
    ::-moz-range-track {background-image: linear-gradient(rgba( ${props.themeColor} ,0.7), rgba( ${props.themeColor} ,0.7))}
    :focus::-webkit-slider-runnable-track {background-image: linear-gradient(rgba( ${props.themeColor} ,0.9), rgba( ${props.themeColor} ,0.9))}
    :focus::-moz-range-track {background-image: linear-gradient(rgba( ${props.themeColor} ,0.9), rgba( ${props.themeColor} ,0.9))}
    ::-ms-track {background-image: linear-gradient(rgba( ${props.themeColor} ,0.9), rgba( ${props.themeColor} ,0.9))}
  `}
`

const Time = styled.span`
  display: inline-block;
  margin-left: 2.5px;
  padding: 0 0.3125rem;
  white-space: nowrap;
  font-size: 0.875rem;
  font-family: Avenir, "Avenir Next", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 1.7;
  text-shadow: 0 0 3px rgb(0 0 0 / 30%);

  ${props => props.themeColor && !props.themeColor.match("#") && `
    color: rgb(${props.themeColor});
    text-shadow: none;
  `}
`

const AudioPlayer = ({ src, progressBar = true, color, ...props }) => {
  const audioRef = createRef()
  const rangeRef = createRef()
  const [loadAudio, setLoadAudio] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [resumeOnDragEnd, setResumeOnDragEnd] = useState(false)
  const intl = useIntl()

  const track = (action) => {
    trackEvent({ category: "Audio", action, name: src })
  }

  const toggle = () => {
    setIsPlaying(!isPlaying)
    audioRef.current[isPlaying ? "pause" : "play"]()
    track(isPlaying ? "Pause" : "Play")
  }

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime)
  }

  const handleSeek = (e) => {
    const value = e.currentTarget.value / 100 * duration
    setCurrentTime(value)
    audioRef.current.currentTime = value
  }

  // Don't load audio on SSR, otherwise duration metadata
  // might not be loaded and displayed.
  useEffect(() => {
    setLoadAudio(true)
  }, [])

  useEffect(() => {
    let resume = false

    if (isDragging) {
      if (isPlaying) {
        resume = true
        toggle()
      }
    } else if (resumeOnDragEnd) {
      toggle()
    }

    setResumeOnDragEnd(resume)
  }, [isDragging])

  return (
    <Wrapper tabIndex="-1" className="audio-player" {...props}>
      <Inner>
        <Controls>
          <Button
            onClick={toggle}
            aria-label={`${intl.formatMessage({ id: isPlaying ? "pause" : "play" })} ${intl.formatMessage({ id: "sound" })}`}
            themeColor={color}
          >
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </Button>
          {progressBar &&
            <Progress>
              <Slider
                type="range"
                ref={rangeRef}
                min="0"
                max="100"
                step="0.1"
                value={duration ? (currentTime / duration) * 100 : 0}
                onInput={handleSeek}
                onChange={handleSeek}
                autoComplete="off"
                role="slider"
                aria-label={intl.formatMessage({ id: "seek" })}
                aria-valuemin="0"
                aria-valuemax={duration}
                aria-valuenow={currentTime}
                aria-valuetext={`${secondsToTime(currentTime)} ${intl.formatMessage({ id: "of" })} ${secondsToTime(duration)}`}
                seek-value={currentTime}
                onMouseUp={() => setIsDragging(false)}
                onTouchEnd={() => setIsDragging(false)}
                onDragEnd={() => setIsDragging(false)}
                themeColor={color}
              />
            </Progress>
          }
          <Time aria-label={intl.formatMessage({ id: "currentTime" })} themeColor={color}>
            -{secondsToTime(currentTime > 0 ? duration - currentTime : duration)}
          </Time>
          {loadAudio &&
            <audio
              ref={audioRef}
              preload="auto"
              onDurationChange={() => setDuration(audioRef.current.duration)}
              onEnded={() => setIsPlaying(false)}
              onTimeUpdate={handleTimeUpdate}
            >
              <source src={src} type="audio/mp3" />
            </audio>
          }
        </Controls>
      </Inner>
    </Wrapper>
  )
}

export default AudioPlayer
