import React from "react"

export default function ArrowIcon({ fill = "#fff", width = 22, transform, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      viewBox="0 0 80 80"
      className="arrow-icon"
      width={width}
      style={{ transform }}
      {...props}
    >
      <path d="M36.52,40.42,17.07,21A10.53,10.53,0,0,1,32,6.07L66.31,40.42,32,74.76A10.53,10.53,0,0,1,17.07,59.87Z" />
    </svg>
  )
}
