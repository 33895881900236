import { useIntl } from "gatsby-plugin-intl"
import getSlugFromString from "./get-slug-from-string"
import siteConfig from "../site-config.json"

export default function getRoomLink(room, lang) {
  const intl = useIntl()

  if (room == 7) {
    return `/${lang}/${siteConfig.roomPath7[lang]}`
  } else {
    return `/${lang}/${getSlugFromString(intl.formatMessage({ id: `roomHeading${room}` }))}`
  }
}
