import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import isEqual from "lodash/isEqual"
import Cookies from "js-cookie"

import CheckmarkIcon from "./icons/Checkmark"
import Alert from "./Alert"

import cookieConfig from "../cookie-config.json"
import { useStore } from "../hooks/store"
import trackEvent from "../utils/track-event"

const Wrapper = styled.div`
  h2 {
    margin-bottom: 0.375rem;
  }
`

const List = styled.ul`
  margin: 0;
  padding: 0.875rem 0;
  list-style: none;
  color: #333;

  p {
    margin: 0 0 0.75rem;
    padding-top: 0.25rem;
    padding-left: 1.875rem;
    font-size: 0.875rem;
  }
`

const Label = styled.label`
  display: flex;
  font-weight: bold;
  line-height: 1.375rem;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`

const Checkbox = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.5rem;
  border: 0.125rem solid #333;
  border-radius: 0.3125rem;
  background: white;

  ${props => props.disabled && `
    background: #333;
  `}
`

const ActionArea = styled.div`
  text-align: right;
`

const ButtonContainer = styled.div`
  ${props => props.flex && `
    display: flex;
    justify-content: flex-end;
  `}
  ${props => props.float && `
    float: right;
  `}
  margin-bottom: 1.25rem;
`

const Button = styled.button`
  padding: 0.3125rem 0.625rem;
  border: 1px solid #333;
  border-radius: 9999px;
  font: 0.875rem/1.375rem ${props => props.theme.headingFont};
  color: #333;
  background: white;
  transition: opacity 0.3s ease;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const ToggleCheckAll = styled.button`
  border: none;
  background: none;
  padding: 0.3125rem 0.625rem;
  font: 0.875rem/1.375rem ${props => props.theme.headingFont};
  color: #333;
  margin: auto 1rem;
`

let updateSuccessMessageTimeout

export default function CookieSettings({ manualMode = false, update = false, ...props }) {
  const intl = useIntl()
  const [store, updateStore] = useStore()
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("")
  const [tempSettings, setTempSettings] = useState(Object.assign({}, store.cookies))

  useEffect(() => {
    setTempSettings(Object.assign({}, store.cookies))
  }, [store.cookies])

  useEffect(() => {
    if (updateSuccessMessage) {
      updateSuccessMessageTimeout = setTimeout(() => {
        setUpdateSuccessMessage("")
      }, 4000)
    }

    return () => {
      clearTimeout(updateSuccessMessageTimeout)
    }
  }, [updateSuccessMessage])

  const track = (name) => {
    trackEvent({ category: "Cookie Settings", action: "Button Click", name })
  }

  const handleAccept = () => {
    updateStore((store) => {
      if (manualMode) {
        Object.keys(tempSettings).forEach((key) => {
          store.cookies[key] = tempSettings[key]
        })
        if (tempSettings.matomo) {
          track(`Accept: ${cookieConfig.cookies.map(({ id }) => id).filter((id) => tempSettings[id]).join(", ")}`)
        }
      } else {
        cookieConfig.cookies.forEach(({ id }) => {
          store.cookies[id] = true
        })
        track("Accept All")
      }

      store.cookies.hide = true
    })

    if (update) {
      setUpdateSuccessMessage("")
      setTimeout(() => {
        setUpdateSuccessMessage(intl.formatMessage({ id: "yourSettingsHaveBeenUpdated" }))
      })
    }
  }

  const handleChange = (key, val) => {
    setTempSettings({
      ...tempSettings,
      [key]: val,
    })
  }

  const handleSetAll = (val) => {
    const toSet = cookieConfig.cookies.reduce((acc, curr) => ({
      ...acc,
      [curr.id]: val,
    }), {})

    setTempSettings({
      ...tempSettings,
      ...toSet,
    })
  }

  const getToggleButton = () => {
    const allChecked = !cookieConfig.cookies.some((el) => !tempSettings[el.id])

    return (
      <ToggleCheckAll onClick={() => handleSetAll(!allChecked)}>
        {intl.formatMessage({ id: allChecked ? "deselectAll" : "selectAll"})}
      </ToggleCheckAll>
    )
  }

  const acceptButton = (
    <ActionArea float={!manualMode && !update}>
      <Button onClick={handleAccept} disabled={update && isEqual(store.cookies, tempSettings)}>
        {intl.formatMessage({ id: update ? "update" : (manualMode ? "acceptSelected" : "acceptAll") })}
      </Button>
      <div aria-live="polite">
        {updateSuccessMessage &&
          <Alert message={updateSuccessMessage} />
        }
      </div>
    </ActionArea>
  )

  return (
    manualMode ?
      <Wrapper
        {...props}
        {...(update ? { id: "cookies" } : {})}
        className={`${props.className || ""} cookie-settings`}
      >
        {update && <h2>{intl.formatMessage({ id: "yourCookieSettings" })}</h2>}
        <List>
          <li>
            <Label disabled>
              <input
                type="checkbox"
                className="structural"
                checked
                disabled
              />
              <Checkbox disabled>
                <CheckmarkIcon stroke="#fff" />
              </Checkbox>
              {intl.formatMessage({ id: "necessary" })}
            </Label>
            <p>{intl.formatMessage({ id: "cookieDesc_necessary" })}</p>
          </li>
          {cookieConfig.cookies.map(({ id, label }) => (
            <li key={id}>
              <Label>
                <input
                  type="checkbox"
                  className="structural"
                  checked={tempSettings[id]}
                  onChange={() => handleChange(id, !tempSettings[id])}
                />
                <Checkbox>
                  {tempSettings[id] && <CheckmarkIcon />}
                </Checkbox>
                {label}
              </Label>
              <p>{intl.formatMessage({ id: `cookieDesc_${id}` })}</p>
            </li>
          ))}
        </List>
        <ButtonContainer flex>
          {getToggleButton()}
          {acceptButton}
        </ButtonContainer>
      </Wrapper>
    :
      <ButtonContainer float>{acceptButton}</ButtonContainer>
  )
}
