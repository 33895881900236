import React from "react"

export default function CheckmarkIcon({ stroke = "#333", ...props }) {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1.74316 6.0709L4.99829 11.3293L10.2567 1.06299" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
