import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import CookieIcon from "./icons/Cookie"
import CaretDownIcon from "./icons/CaretDown"
import CaretUpIcon from "./icons/CaretUp"
import CookieSettings from "./CookieSettings"
import CookieLink from "./CookieLink"

import { useStore } from "../hooks/store"

const Wrapper = styled.section`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  max-height: 100%;
  padding: 1.25rem 1.25rem 0;
  overflow: auto;
  background: white;

  h2 {
    margin: 0 0 0.375rem;
    color: #333;
    font: 1rem/1.375rem ${props => props.theme.headingFont};
    text-transform: uppercase;

    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.375rem;
      float: left;
    }
  }
`

const Inner = styled.div`
  max-width: 48.25rem;
  margin: 0 auto;
`

const Text = styled.div`
  margin-bottom: 1.125rem;

  > div, p {
    display: inline;
  }
`

const ToggleButton = styled.button`
  border: 0;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: #333;
  background: none;

  svg {
    margin-left: 0.5rem;
  }
`

export default function CookieBanner({ lang, ...props }) {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const [manualMode, setManualMode] = useState(false)
  const [store] = useStore()

  useEffect(() => {
    setShow(!store.cookies.hide)
  }, [store.cookies.hide])

  useEffect(() => {
    if (typeof window._paq === "undefined") {
      return
    }
    if (store.cookies.matomo) {
      window._paq.push(["setConsentGiven"])
    } else {
      window._paq.push(["requireConsent"])
    }
  }, [store.cookies.matomo])

  const { allTextsYaml } = useStaticQuery(graphql`
    query {
      allTextsYaml(filter: { _id: { eq: "cookieBanner" } }) {
        nodes {
          lang
          text {
            html
          }
        }
      }
    }
  `)

  const cookieText = allTextsYaml.nodes.find((x) => x.lang === lang)

  const Caret = manualMode ? CaretUpIcon : CaretDownIcon

  if (!show) {
    return null
  }

  return (
    <Wrapper {...props}>
      <Inner>
        <h2><CookieIcon /> {intl.formatMessage({ id: "cookies" })}</h2>
        <Text>
          <div dangerouslySetInnerHTML={{ __html: get(cookieText, "text.html") }} /> <CookieLink lang={lang} text={intl.formatMessage({ id: "cookieBannerLinkText" })} />
        </Text>
        <ToggleButton onClick={() => setManualMode(!manualMode)} aria-expanded={manualMode}>
          {intl.formatMessage({ id: "cookieSettingsToggleText" })} <Caret />
        </ToggleButton>
        <CookieSettings manualMode={manualMode} />
      </Inner>
    </Wrapper>
  )
}
