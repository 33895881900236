import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import getRoomLink from "../utils/get-room-link"

import Logo from "./LogoFull"
import ActionButton from "./ActionButton"
import Arrow from "./icons/Arrow"
import base64svg from "./icons/base64svg"

const HEADER_HEIGHT = "3rem"

const Wrapper = styled.header`
  position: relative;
  z-index: 100;
  width: 100%;
  height: ${HEADER_HEIGHT};
  overflow: visible;
`

const Inner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${HEADER_HEIGHT};
  display: flex;
  flex-flow: row nowrap;
  color: white;
  background: ${props => props.bgColor || (props.room ? props.theme.roomColors[props.room].base : props.theme.roomColors.default.base)};

  ${props => props.increaseTitleContrast && `
    ::after {
      content: "";
      position: absolute;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: linear-gradient(to right, transparent, rgba(80,80,80,${props.increaseTitleContrast}) 80%);
      mix-blend-mode: luminosity;
    }
  `}
`

const LogoLink = styled(Link)`
  position: relative;
  display: block;
  flex-basis: 8em;
  flex-shrink: 0;
  cursor: pointer;
  transform-origin: top left;
  transition: transform .3s ease-in-out, filter .3s ease-in-out;
  filter: none;
  padding: 0.25em;
  color: white;
  text-decoration: none;

  :hover {
    filter: drop-shadow(2px 5px 5px rgba(0,0,0,0.5));
  }

  :hover,
  :focus {
    color: white;
    transform: scale(1.05);
  }
`

const StyledLogoFull = styled(Logo)`
  position: absolute;
  top: 0.25rem;
  left: 1.8rem;
  width: 3.5em;

  :before {
    position: absolute;
    display: inline-block;
    content: "";
    background: url(${base64svg(<Arrow transform="rotate(180deg)"/>)}) center center no-repeat;
    background-size: contain;
    height: 100%;
    width: 1.1rem;
    left: -1.4rem;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
  }
`

const TitleWrapper = styled.div`
  flex: 1 auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: center;
  padding: 0.25em;
  padding-right: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  ${props => props.titleColor && `
    color: ${props.titleColor};
  `}

  h1, h2 {
    width: 100%;
    margin: 0;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 1.2em;
    font-family: ${props => props.theme.secondaryFont};
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;

    .main-heading {
      display: block;
      font-size: 1.25rem;
      line-height: 1.2em;
      font-family: ${props => props.theme.headingFont};
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

const SymbolImg = styled.img`
  margin-top: .2rem;
  margin-right: .4rem;
  height: 2.6rem;
  flex-shrink: 0;

  ${ props => props.bigger && `
    height: 3.2rem;
  `}

  ${ props => props.nomargin && `
    margin-top: 0;
    height: 2.8rem;
  `}
`

const SymbolWrapper = styled.div`
  flex-grow: 2;
  text-align: right;

  img {
    margin-top: .25rem;
    margin-right: .4rem;
    height: 2.6rem;
    flex-shrink: 0;
  }

  ${props => props.bigger && `
    img { height: 3.2rem; }
  `}

  img.bigger {
    height: 3.2rem;
  }

  img.nomargin {
    margin-top: 0;
  }

  img.bigger.nomargin,
  img.nomargin.bigger {
    margin-top: 0;
    height: 3.4rem;
  }
`

const HeaderSub = ({
  lang,
  title,
  subtitle,
  headingLevel = "h1",
  titleColor = "white",
  color,
  increaseTitleContrast = null,
  isTransparent = false,
  room,
  roomSymbol,
  roomSymbolBigger = null,
  roomSymbolNoMargin = null,
  ...props
}) => {
  const intl = useIntl()
  const roomTitle = subtitle ? subtitle : intl.formatMessage({ id: `roomHeading${room}` })
  let icon = null

  if (roomSymbol) {
    if (typeof roomSymbol === "string") {
      icon = <SymbolImg src={roomSymbol} alt="" bigger={roomSymbolBigger} nomargin={roomSymbolNoMargin} role="presentation" />
    } else {
      icon = title || subtitle ? roomSymbol : <SymbolWrapper bigger={roomSymbolBigger} nomargin={roomSymbolNoMargin}>{roomSymbol}</SymbolWrapper>
    }
  }

  const getHeading = (content) => {
    switch (headingLevel) {
      case "h1":
        return <h1>{content}</h1>
      case "h2":
        return <h2>{content}</h2>
      default:
        return content
    }
  }

  const handleGoBack = (e) => {
    if (window.roomScrollPos && window.roomScrollPos.room === room) {
      window.roomScrollPos.autoScroll = true
    }
  }

  return (
    <Wrapper>
      <Inner
        bgColor={isTransparent ? "transparent" : color}
        room={room}
        increaseTitleContrast={increaseTitleContrast}
      >
        <LogoLink to={getRoomLink(room, lang)} onClick={handleGoBack}>
          <span className="structural">{intl.formatMessage({ id: "backTo" })} {roomTitle}</span>
          <StyledLogoFull
            lang={lang}
            className={`logo-mini${props.className || ""}`}
            mini
            color={color}
            room={room}
           />
        </LogoLink>
        {title &&
          <TitleWrapper titleColor={titleColor}>
            {roomTitle ?
              getHeading(<span>{roomTitle}{title && <span className="main-heading"><span className="structural"> – </span>{title}</span>}</span>) :
              getHeading(<span className="main-heading">{title}</span>)
            }
          </TitleWrapper>
        }
        {icon}
      </Inner>
    </Wrapper>
  )
}

export default HeaderSub
